<template>
  <div class="pt-4" style="padding-bottom: 15rem">
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div>
      <div v-b-toggle.new-shipments-collapse variant="primary" class="shipment-toggle w-100 d-inline-flex justify-content-between"
      :style="getStyle('new-shipments-collapse')">
        <div class="d-inline-flex">
          <p class="my-auto mr-4">New Shipment</p>
          <div class="text-center shipments-number black">{{ newShipments.length }}</div>
        </div>
        <i class="arrow my-auto" :class="getClass('new-shipments-collapse')"></i>
      </div>
      <b-collapse id="new-shipments-collapse" visible>
        <b-card v-if="newShipments.length > 0" class="mb-0">
          <table>
            <tr>
              <th v-for="(header, index) in shipmentHeaders" :key="index">{{ header }}</th>
            </tr>
            <tr v-for="(shipment, index) in newShipments" :key="index" >
              <td>{{ shipment.salesShipmentNo }}</td>
              <td>{{ shipment.postingDate }}</td>
              <td>{{ shipment.driver }}</td>
              <td>{{ shipment.vehicle }}</td>
              <td>{{ shipment.item }}</td>
              <td>{{ shipment.customer }}</td>
              <td>{{ shipment.depart }}</td>
              <td>{{ shipment.arrive }}</td>
              <td>{{ shipment.customerWeight }}</td>
            </tr>
          </table>
        </b-card>
      </b-collapse>
    </div>
    <div>
      <div v-b-toggle.active-shipments-collapse variant="primary" class="mt-5 shipment-toggle w-100 d-inline-flex justify-content-between"
      :style="getStyle('active-shipments-collapse')">
        <div class="d-inline-flex">
          <p class="my-auto mr-4">Active Shipment</p>
          <div class="text-center shipments-number yellow">{{ activeShipments.length }}</div>
        </div>
        <i class="arrow my-auto" :class="getClass('active-shipments-collapse')"></i>
      </div>
      <b-collapse id="active-shipments-collapse" visible>
        <b-card v-if="activeShipments.length > 0" class="mb-0">
          <table>
            <tr>
              <th v-for="(header, index) in shipmentHeaders" :key="index">{{ header }}</th>
            </tr>
            <tr v-for="(shipment, index) in activeShipments" :key="index" >
              <td>{{ shipment.salesShipmentNo }}</td>
              <td>{{ shipment.postingDate }}</td>
              <td>{{ shipment.driver }}</td>
              <td>{{ shipment.vehicle }}</td>
              <td>{{ shipment.item }}</td>
              <td>{{ shipment.customer }}</td>
              <td>{{ shipment.depart }}</td>
              <td>{{ shipment.arrive }}</td>
              <td>{{ shipment.customerWeight }}</td>
            </tr>
          </table>
        </b-card>
      </b-collapse>
    </div>
    <div>
      <div v-b-toggle.complete-shipments-collapse variant="primary" class="mt-5 shipment-toggle w-100 d-inline-flex justify-content-between"
      :style="getStyle('complete-shipments-collapse')">
        <div class="d-inline-flex">
          <p class="my-auto mr-4">Complete Shipment</p>
          <div class="text-center shipments-number green">{{ completedShipments.length }}</div>
        </div>
        <i class="arrow my-auto" :class="getClass('complete-shipments-collapse')"></i>
      </div>
      <b-collapse id="complete-shipments-collapse" visible>
        <b-card class="mb-0" v-if="completedShipments.length > 0">
          <table>
            <tr>
              <th v-for="(header, index) in shipmentHeaders" :key="index">{{ header }}</th>
            </tr>
            <tr v-for="(shipment, index) in completedShipments" :key="index" >
              <td>{{ shipment.salesShipmentNo }}</td>
              <td>{{ shipment.postingDate }}</td>
              <td>{{ shipment.driver }}</td>
              <td>{{ shipment.vehicle }}</td>
              <td>{{ shipment.item }}</td>
              <td>{{ shipment.customer }}</td>
              <td>{{ shipment.depart }}</td>
              <td>{{ shipment.arrive }}</td>
              <td>{{ shipment.customerWeight }}</td>
            </tr>
          </table>
        </b-card>
      </b-collapse>
    </div>
    <div>
      <div v-b-toggle.cancel-shipments-collapse variant="primary" class="mt-5 shipment-toggle w-100 d-inline-flex justify-content-between"
      :style="getStyle('cancel-shipments-collapse')">
        <div class="d-inline-flex">
          <p class="my-auto mr-4">Cancel Shipment</p>
          <div class="text-center shipments-number red">{{ cancelledShipments.length }}</div>
        </div>
        <i class="arrow my-auto" :class="getClass('cancel-shipments-collapse')"></i>
      </div>
      <b-collapse id="cancel-shipments-collapse" visible>
        <b-card  v-if="cancelledShipments.length > 0" class="mb-0">
          <table>
            <tr>
              <th v-for="(header, index) in shipmentHeaders" :key="index">{{ header }}</th>
            </tr>
            <tr v-for="(shipment, index) in cancelledShipments" :key="index" >
              <td>{{ shipment.salesShipmentNo }}</td>
              <td>{{ shipment.postingDate }}</td>
              <td>{{ shipment.driver }}</td>
              <td>{{ shipment.vehicle }}</td>
              <td>{{ shipment.item }}</td>
              <td>{{ shipment.customer }}</td>
              <td>{{ shipment.depart }}</td>
              <td>{{ shipment.arrive }}</td>
              <td>{{ shipment.customerWeight }}</td>
            </tr>
          </table>
        </b-card>
      </b-collapse>
    </div>
  </div>
  </div>
</template>
<script>
import * as dashboardActions from "../store/modules/dashboard/actions"
import { addCommasToNumbers } from "../utils/helpers"

export default {
  name: "Dashboard",
  data() {
    return {
      isLoading: false,
      isCollapse1: true,
      isCollapse2: true,
      isCollapse3: true,
      isCollapse4: true,
      newShipments: [],
      activeShipments: [],
      completedShipments: [],
      cancelledShipments: [],
      shipmentHeaders: ['Sales Shipment No', 'Posting Date', 'Driver', 'Vehicle', 'Item', 'Customer', 'Depart', 'Arrive', 'Customer Weight'],
    }
  },
  methods: {
    getList() {
      this.isLoading = true
      this.$store.dispatch(dashboardActions.DASHBOARD_LIST_REQUEST, {})
      .then(res => {
        this.newShipments = res.response.newShipments
        if (this.newShipments.length <= 0) this.isCollapse1 = true
        this.activeShipments = res.response.activeShipments
        if (this.activeShipments.length <= 0) this.isCollapse2 = true
        this.completedShipments = res.response.completedShipments
        if (this.completedShipments.length <= 0) this.isCollapse3 = true
        this.cancelledShipments = res.response.cancelledShipments
        if (this.cancelledShipments.length <= 0) this.isCollapse4 = true
        this.parseShipments()
      })
      .catch(err => console.log(err))
      .finally(() => this.isLoading = false)
    },
    parseShipments() {
      let shipmentsNames = ['newShipments', 'activeShipments', 'completedShipments', 'cancelledShipments']
      shipmentsNames.forEach(name => {
        this[name].map(el => {
          if (el.customerWeight) el.customerWeight = addCommasToNumbers(el.customerWeight)
          return el
        })
      })
    },
    getStyle(collapseId) {
      let isCollapse = this.isCollapseByCollapseId(collapseId)
      return {
        'border-bottom-left-radius': isCollapse ? '10px' : '0', 
        'border-bottom-right-radius': isCollapse ? '10px' : '0'
      }
    },
    getClass(collapseId) {
      let isCollapse = this.isCollapseByCollapseId(collapseId)
      return {
        'down': !isCollapse,
        'right': isCollapse
      }
    },
    isCollapseByCollapseId(collapseId) {
      let isCollapse = false
      if (collapseId == 'new-shipments-collapse') {
        if (this.newShipments.length > 0) isCollapse = this.isCollapse1
        else isCollapse = true // nothing to show
      }
      if (collapseId == 'active-shipments-collapse') {
        if (this.activeShipments.length > 0) isCollapse = this.isCollapse2
        else isCollapse = true // nothing to show
      }
      if (collapseId == 'complete-shipments-collapse') {
        if (this.completedShipments.length > 0) isCollapse = this.isCollapse3
        else isCollapse = true // nothing to show
      }
      if (collapseId == 'cancel-shipments-collapse') {
        if (this.cancelledShipments.length > 0) isCollapse = this.isCollapse4
        else isCollapse = true // nothing to show
      }
      return isCollapse
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'new-shipments-collapse') this.isCollapse1 = !isJustShown
      if (collapseId == 'active-shipments-collapse') this.isCollapse2 = !isJustShown
      if (collapseId == 'complete-shipments-collapse') this.isCollapse3 = !isJustShown
      if (collapseId == 'cancel-shipments-collapse') this.isCollapse4 = !isJustShown
    })
    this.getList()
  }
}
</script>
<style lang='scss' scoped>
.shipment-toggle{
  background-color: white;
  padding: 20px 30px;
  color: black;
  font-weight: 500;
  font-size: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%);
  cursor: pointer;
}
.collapse.show .card{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.card, .card-body .accordion .card:hover {
  box-shadow: 0px 10px 10px 0 rgb(119 119 119 / 10%);
}
.shipments-number{
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  height: 30px;
  width: 60px;
}
.shipments-number {
  &.black {
    background-color: #000000;
  }
  &.yellow{
    background-color: #FFBA00;
  }
  &.green{
    background-color: #6DC860;
  }
  &.red{
    background-color: #E23420;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  td, th {
    border: 1px solid #D8D8DA;
    padding: 10px;
  }
  tr {
    td {
      color: #6e7072;
      &:first-child {
        color: #291B1C!important;
        font-weight: 400!important;
      }
    }
    &:not(:last-child) {
      box-shadow: none!important;
      td {
        padding: 10px!important;
        border: 1px solid #D8D8DA!important;
        &:first-child {
          color: #291B1C!important;
          font-weight: 400!important;
        }
      }
    }
    th {
      font-weight: 600;
      color: #291B1C;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    tr {
      display: flex;
      flex-direction: column;
    }
    td, th {
      height: 6rem;
      border-radius: 0px !important;
    }
  }
}
.card-body{
  padding-top: 10px;
}
</style>